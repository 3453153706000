export function beautifyString(char: string): string {
  return char
    .replace(/_/g, " ") // Replace all underscores with spaces
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Rejoin the words into a single string
}

export function formatBudgetCurrency(value: string): string {
  const parts = value.split(".");
  const integerPart = parts[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const decimalPart = parts[1] ? `.${parts[1]}` : "";
  return `$${integerPart}${decimalPart}`;
}

export function formatCurrency(number: number, currency: string, options: Intl.NumberFormatOptions = {}): string {
  // Determine the locale based on the currency
  let locale: string;
  switch (currency) {
    case "USD":
      locale = "en-US";
      break;
    case "CNY":
      locale = "zh-CN";
      break;
    default:
      // This case will never be reached because the currency type is limited to 'USD' | 'CNY'
      throw new Error("Unsupported currency");
  }

  // Create a formatter with the specified currency and any additional options
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    ...options,
  });

  return formatter.format(number);
}

export function formatDateFromString(
  dateString: string | null,
  locale = "en-US",
  options: Intl.DateTimeFormatOptions = {},
): string {
  const date = dateString ? new Date(dateString) : new Date(); // Month is 0-indexed in JavaScript Date objects

  // Use Intl.DateTimeFormat to format the date
  const formatter = new Intl.DateTimeFormat(locale, options);
  return formatter.format(date);
}

export function formatNumber(number: number, locale = "en-US", options: Intl.NumberFormatOptions = {}): string {
  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(number);
}

export function capitalizeFirstLetter(word: string): string {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const parseFormattedCurrency = (formattedValue: string): number => {
  // Remove dollar sign, commas, and any non-numeric characters except the decimal point
  const numericString = formattedValue.replace(/[^0-9.]/g, "");
  return numericString === "" ? 0 : parseFloat(numericString);
};

export function convertDateFormat(stringDate: string): string {
  const date = new Date(stringDate);

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// function to add comma to numbers
export function addCommaToNumber(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Flattens children from a structured CardsResponse array while excluding specific entries.
 * @param data - Array of CardsResponse data.
 * @param excludeLast - Whether to exclude the last item in the data array.
 * @param excludeChildId - ID of children to exclude from the results.
 * @returns An array of children with added parentIndex, excluding specified children.
 */
export function flattenChildrenWithExclusion(
  data: CardsResponse[],
  excludeLast = true,
  excludeChildId = "Remaining Data",
): GenericType[] {
  if (!Array.isArray(data)) {
    return [];
  }

  const endIndex = excludeLast ? -1 : undefined; // Determine if the last item should be excluded
  return data.slice(0, endIndex).flatMap((item, parentIndex) =>
    item.children
      .filter((child) => child.child_id !== excludeChildId)
      .map((child) => ({
        parentIndex, // Include parent index for back-reference
        ...child, // Spread all properties of the child
      })),
  );
}

/**
 * Merges two sets of children data by prefixing properties with 'first_' or 'second_'.
 * Entries unique to the second set are also included with first set properties set to null.
 * @param firstChildren Array of Child objects from the first dataset.
 * @param secondChildren Array of Child objects from the second dataset.
 * @param thirdChildren Array of Child objects from the second dataset.
 * @returns Merged array of GenericType objects.
 */
export function mergeChildrenData(
  firstChildren: ChildResponse[],
  secondChildren: ChildResponse[],
  thirdChildren: ChildResponse[] = [],
): GenericType[] {
  // Create a map for quick lookup of second children
  const secondChildrenMap = new Map(secondChildren.map((child) => [child.child_id, child]));
  const secondProcessed = new Map<string, boolean>();

  // Create a map for quick lookup of third children if provided
  const thirdChildrenMap = new Map(thirdChildren.map((child) => [child.child_id, child]));
  const thirdProcessed = new Map<string, boolean>();

  const addPrefixToChild = (child: ChildResponse, prefix: string): GenericType => {
    const result: GenericType = { child_id: child.child_id, name: child.name };
    Object.keys(child).forEach((key) => {
      if (key !== "child_id" && key !== "name") {
        result[`${prefix}${key}`] = child[key];
      }
    });
    return result;
  };

  // Combine data from the first set with the possible corresponding entries from the second and third sets
  const output = firstChildren.reduce((acc, child) => {
    const secondChild = secondChildrenMap.get(child.child_id);
    const thirdChild = thirdChildrenMap.get(child.child_id);
    const firstResult = addPrefixToChild(child, "first_");
    const secondResult = secondChild ? addPrefixToChild(secondChild, "second_") : {};
    const thirdResult = thirdChild ? addPrefixToChild(thirdChild, "third_") : {};
    const combinedResult = { ...firstResult, ...secondResult, ...(thirdChildren.length > 0 ? thirdResult : {}) };
    acc.push(combinedResult);
    if (secondChild) {
      secondProcessed.set(secondChild.child_id, true);
    }
    if (thirdChild) {
      thirdProcessed.set(thirdChild.child_id, true);
    }

    return acc;
  }, [] as GenericType[]);

  // Handle children in the second dataset that are not in the first
  return secondChildren.reduce((acc, child) => {
    if (!secondProcessed.has(child.child_id)) {
      const secondResult = addPrefixToChild(child, "second_");
      const thirdResult = thirdChildren.length > 0 ? addPrefixToChild(child, "third_") : {};
      const firstResult = addPrefixToChild({ child_id: child.child_id, name: child.name } as ChildResponse, "first_");
      Object.keys(firstResult).forEach((key) => {
        if (!key.startsWith("child_id") && !key.startsWith("name")) {
          firstResult[key] = null;
        }
      });
      const combinedResult = { ...firstResult, ...secondResult, ...(thirdChildren.length > 0 ? thirdResult : {}) };
      acc.push(combinedResult);
    }
    return acc;
  }, output);
}

/**
 * Restructures data for table display with flattened structure.
 * @param firstChildren Array of Child objects from the first dataset.
 * @param secondChildren Array of Child objects from the second dataset.
 * @param thirdChildren Optional array of Child objects from the third dataset.
 * @returns Flattened data array for table display.
 */
export function restructureDataForTable(
  firstChildren: ChildResponse[],
  secondChildren: ChildResponse[],
  thirdChildren: ChildResponse[] = [],
): GenericType[] {
  const combinedChildren = [...firstChildren, ...secondChildren, ...thirdChildren];
  const groupedData: { [key: string]: GenericType[] } = {};

  const addMissingKeys = (child: Partial<ChildResponse>, keys: Set<string>, defaultValues = false): GenericType => {
    const result: GenericType = { ...child };
    keys.forEach((key) => {
      if (key !== "child_id" && key !== "name") {
        if (defaultValues) {
          // Set default values based on the type of the key
          result[key] = typeof result[key] === "number" ? 0 : "";
        } else {
          // Ensure undefined keys are set to default values based on their expected type
          result[key] = child[key] !== undefined ? child[key] : typeof result[key] === "number" ? 0 : "";
        }
      }
    });
    return result;
  };

  const allKeys = new Set<string>();
  combinedChildren.forEach((child) => {
    Object.keys(child).forEach((key) => {
      if (key !== "child_id" && key !== "name") {
        allKeys.add(key);
      }
    });
  });

  firstChildren.forEach((child) => {
    if (!groupedData[child.child_id]) {
      groupedData[child.child_id] = [];
    }
    groupedData[child.child_id].push(addMissingKeys(child, allKeys));
    const secondElement = secondChildren.find((c) => c.child_id === child.child_id);
    if (!secondElement) {
      groupedData[child.child_id].push(addMissingKeys({ child_id: child.child_id, name: child.name }, allKeys, true));
    }
  });

  secondChildren.forEach((child) => {
    if (!groupedData[child.child_id]) {
      groupedData[child.child_id] = [];
      groupedData[child.child_id].push(addMissingKeys({ child_id: child.child_id, name: child.name }, allKeys, true));
    }
    groupedData[child.child_id].push(addMissingKeys(child, allKeys));
  });

  thirdChildren.forEach((child) => {
    if (!groupedData[child.child_id]) {
      groupedData[child.child_id] = [];
    }
    groupedData[child.child_id].push(addMissingKeys(child, allKeys));
  });

  const output: GenericType[] = [];
  Object.keys(groupedData).forEach((child_id) => {
    const children = groupedData[child_id];

    // Ensure there are exactly two rows per parent row
    while (children.length < 2) {
      const emptyRow: GenericType = { child_id, name: children[0].name };
      allKeys.forEach((key) => {
        emptyRow[key] = typeof children[0][key] === "number" ? 0 : "";
      });
      children.push(emptyRow);
    }

    output.push(...children);
  });

  return output;
}

export function groupObjectsBy<T>(array: T[], key: keyof T): { [key: string]: T[] } {
  return array.reduce(
    (result, currentValue) => {
      const groupKey = String(currentValue[key]);
      (result[groupKey] = result[groupKey] || []).push(currentValue);
      return result;
    },
    {} as { [key: string]: T[] },
  );
}

// Function to calculate Click-Through Rate (CTR)
export function calculateCTR(clicks: number, impressions: number): number {
  return (clicks / impressions) * 100;
}

// Function to calculate Cost Per Mille (CPM)
export function calculateCPM(cost: number, impressions: number): number {
  return (cost / impressions) * 1000;
}

// Function to calculate Cost Per Click (CPC)
export function calculateCPC(cost: number, clicks: number): number {
  return cost / clicks;
}

export function beautifyPlatform(platform: string): string {
  switch (platform) {
    case "mfw":
      return "Mafwengo";
    case "ups":
      return "UPS";
    case "ctrip":
      return "Ctrip";
    case "ipinyou":
      return "iPinYou";
    case "wechat":
      return "WeChat";
    case "tencent":
      return "Tencent";
    case "qunar":
      return "Qunar";
    case "dianping":
      return "Dianping";
    default:
      return platform;
  }
}

export function handleReadFileContent(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
    reader.readAsText(file);
  });
}

export function handleReadFirstChunk(
  file: File,
  chunkSize: number = 1024 * 512, // Default to 1MB
): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const firstChunk = file.slice(0, chunkSize);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsText(firstChunk);
  });
}

export function formatBytes(bytes: number | null, decimals = 1): string {
  if (bytes === null) return "";
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${size}${sizes[i].toLowerCase()}`;
}

export function getExcelColumnLetters(n: number): string[] {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const result: string[] = [];

  for (let i = 0; i < n; i++) {
    let letters = "";
    let index = i;

    while (index >= 0) {
      letters = alphabet[index % 26] + letters;
      index = Math.floor(index / 26) - 1;
    }

    result.push(letters);
  }

  return result;
}

/**
 * Sanitizes a string by removing non-alphanumeric characters (except spaces, underscores, and hyphens)
 * and trims the value to a specified length.
 *
 * @param {string} value - The input value to sanitize.
 * @param {number} limit - The maximum length of the string.
 * @returns {string} - The sanitized and trimmed string.
 */
export function sanitizeInput(value: string, limit: number): string {
  let sanitizedValue = value.replace(/[^a-zA-Z0-9 _-]/g, ""); // Remove non-alphanumeric characters
  if (sanitizedValue.length > limit) {
    sanitizedValue = sanitizedValue.substring(0, limit); // Trim to the length limit
  }
  return sanitizedValue;
}

/**
 * Maps the provided filter values to a format containing `id` and `name` and then sorts them alphabetically by `name`.
 *
 * This function is useful for transforming raw filter data into a format that can be easily used in dropdowns or other UI elements, where you want to display the `name` but track the `id`.
 *
 * @param {Record<string, string>[]} values - The array of filter values to map and sort. Each value should contain at least `name` and `display_name` fields.
 * @param {string} idKey - An optional parameter to set the id
 * @returns {DropdownType[]} - An array of objects, each containing `id` and `name` properties, sorted alphabetically by `name`.
 *
 * Example:
 * Input:
 * [
 *   { name: 'male', display_name: 'Male' },
 *   { name: 'female', display_name: 'Female' }
 * ]
 * Output:
 * [
 *   { id: 'female', name: 'Female' },
 *   { id: 'male', name: 'Male' }
 * ]
 */
export const mapAndSortDropdownValues = (values: Record<string, string>[], idKey = "name"): DropdownType[] => {
  return values
    .map((value) => ({ id: value[idKey], name: value.display_name }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

/**
 * Maps the headers from the preview data to an array of CSV headers with original values and errors.
 *
 * @param {string[]} previewHeaders - The array of header strings from the preview data.
 * @returns {{ originalValue: string; value: string; error: boolean }[]} - Mapped CSV headers with original values and error flags.
 */
export function mapCsvHeaders(previewHeaders: string[]): { originalValue: string; value: string; error: boolean }[] {
  return previewHeaders.map((header) => ({
    originalValue: header,
    value: header,
    error: false,
  }));
}

/**
 * Maps the first ten rows of CSV data using the provided headers.
 *
 * @param {string[][]} previewRows - The array of rows from the preview data, excluding headers.
 * @param {Array<{ value: string; error: boolean }>} csvHeaders - The array of CSV headers.
 * @returns {Record<string, { value: string; error: boolean }>[]} - Mapped rows with corresponding header values and error flags.
 */
export function mapFirstTenRows(
  previewRows: string[][],
  csvHeaders: Array<{ value: string; error: boolean }>,
): Record<string, { value: string; error: boolean }>[] {
  return previewRows.map((row) => {
    const completeRow: Record<string, { value: string; error: boolean }> = {};
    csvHeaders.forEach((header, index) => {
      completeRow[header.value] = { value: row[index], error: false };
    });
    return completeRow;
  });
}
